import React from 'react';
import view from "../findDealer.module.scss";

const RoadSignIcon = () => {
  return (
        
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={view["icon-fills"]} fill-rule="evenodd" clip-rule="evenodd" d="M2.82843 21L21 2.82843L39.1716 21L21 39.1716L2.82843 21ZM21 42L0 21L21 0L42 21L21 42ZM29.4827 16.3224L33.6364 20.4761C33.9068 20.7465 33.9068 21.1849 33.6364 21.4552L29.4827 25.6089C29.0472 26.0445 28.301 25.7341 28.301 25.1194V23.3993C25.4079 23.5787 23.1088 25.9894 23.1088 28.9269V31.3499C23.1088 31.7322 22.7989 32.0422 22.4166 32.0422C22.0342 32.0422 21.7243 31.7322 21.7243 31.3499V14.7351C21.7243 14.3528 22.0342 14.0428 22.4166 14.0428H23.1683L20.6859 11.5604L18.2035 14.0428H18.9552C19.3375 14.0428 19.6474 14.3528 19.6474 14.7351V31.3499C19.6474 31.7322 19.3375 32.0422 18.9552 32.0422C18.5728 32.0422 18.2629 31.7322 18.2629 31.3499V15.4274H16.5322C15.9163 15.4274 15.6077 14.6805 16.0426 14.2456L20.1963 10.0919C20.4667 9.82157 20.905 9.82157 21.1753 10.0919L25.329 14.2456C25.7646 14.6811 25.4546 15.4274 24.8395 15.4274H23.1088V20.1432C24.6605 19.1653 26.4546 18.6103 28.301 18.5485V16.812C28.301 16.1957 29.0482 15.8879 29.4827 16.3224ZM29.6855 22.6964V23.4481L32.1679 20.9657L29.6855 18.4833V19.235C29.6855 19.6173 29.3756 19.9272 28.9932 19.9272H28.6471C26.6383 19.9272 24.6843 20.6023 23.1088 21.8336V24.7778C24.3729 23.0948 26.385 22.0041 28.6471 22.0041H28.9932C29.3756 22.0041 29.6855 22.3141 29.6855 22.6964Z" fill="#E82C2A"/>
    </svg>
  )
}

export default RoadSignIcon;