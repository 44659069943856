import React from 'react'
import InnerHTML from 'dangerously-set-html-content'

const $script = typeof window !== `undefined` ? require('scriptjs') : null

function HTML(props) {

    function parseScript (scriptString){
        var doc = document.implementation.createHTMLDocument(); // Sandbox
        doc.body.innerHTML = scriptString; // Parse HTML properly
        var parsedScripts = [].map.call(doc.getElementsByTagName('script'), function(el) {
            let thisScript = {
                textContent: el.textContent.length > 0 ? el.textContent : null,
                src:  typeof el.attributes.src !== "undefined" ? el.attributes.src.value : null
            }
            return thisScript;
        });
        return parsedScripts
    }

    function execScripts (theParsedScripts){
        let srcScripts = theParsedScripts.filter((s) => { return s.src !== null });
        let innerScripts = theParsedScripts.filter((s) => { return s.textContent !== null });

        let loadScripts = []
        srcScripts.forEach((s) => {
            loadScripts.push(s.src)
        })
        let evalScripts = []
        innerScripts.forEach((s) => {
            evalScripts.push(s.textContent)
        });

        if(loadScripts.length > 0){
            $script(loadScripts,function (){
                evalScripts.forEach((s) => {
                    eval(s)
                })
            });
        }else{
            evalScripts.forEach((s) => {
                eval(s)
            })
        }
    }

    if(typeof window !== 'undefined'){
        let theParsedScripts = parseScript(props.data.scriptJS)
        execScripts(theParsedScripts)
    }

    return (
        <InnerHTML html={props.data.rawHTML} />
    )
}

export default HTML