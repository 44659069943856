import React from 'react';
import view from "../findDealer.module.scss";


const PhoneIcon = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={view["icon-fills"]} fill-rule="evenodd" clip-rule="evenodd" d="M40 21C40 31.4934 31.4934 40 21 40C10.5066 40 2 31.4934 2 21C2 10.5066 10.5066 2 21 2C31.4934 2 40 10.5066 40 21ZM42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21ZM24.0413 21.9103L26.7796 24.4C26.9094 24.5177 26.988 24.6815 26.9987 24.8565C27.0094 25.0314 26.9513 25.2036 26.8368 25.3363L25.5839 26.7987C25.4676 26.9157 25.3121 26.9855 25.1474 26.9947C25.1252 26.9961 25.0416 27.0001 24.9056 27.0001C23.9747 27.0001 20.5945 26.8027 17.896 24.1049C14.8028 21.0104 14.9955 17.0213 15.0056 16.8529C15.015 16.6882 15.0845 16.5326 15.2009 16.4157L16.6627 15.1621C16.7958 15.0481 16.9681 14.9904 17.143 15.0013C17.318 15.0123 17.4818 15.091 17.5996 15.2207L20.0886 17.9583C20.2064 18.0876 20.2693 18.2578 20.2639 18.4326C20.2585 18.6075 20.1852 18.7734 20.0597 18.8953L18.4033 20.5079C18.4753 20.8932 18.7185 21.6752 19.5214 22.4788C20.3244 23.2798 21.1044 23.5229 21.4924 23.597L23.1044 21.9393C23.2263 21.8139 23.3922 21.7407 23.567 21.7353C23.7418 21.7299 23.9119 21.7927 24.0413 21.9103Z" fill="#E82C2A"/>
    </svg>
  )
}

export default PhoneIcon;